import Vue from "vue";
import VueRouter from "vue-router";

// Login
import Login from "@/views/Login";

// Promotional
import Dashboard from "@/views/Dashboard";

// Promotional
import Promotional from "@/views/Promotional/List";
import PromotionalEditor from "@/views/Promotional/Editor";

// Car Repair
import CarRepair from "@/views/CarRepair/List";
import CarRepairEditor from "@/views/CarRepair/Editor";

// Occurrence
import Occurrence from "@/views/Occurrence/List";
import OccurrenceEditor from "@/views/Occurrence/Editor";

// Quotation
import Quotation from "@/views/Quotation/List";
import QuotationEditor from "@/views/Quotation/Editor";

// Inspection
import Inspection from "@/views/Inspection/List";
import InspectionEditor from "@/views/Inspection/Editor";

// Contract
import Contract from "@/views/Contract/List";
import ContractEditor from "@/views/Contract/Editor";

// Company
import Company from "@/views/Company/List";
import CompanyEditor from "@/views/Company/Editor";

// User
import User from "@/views/User/List";
import UserEditor from "@/views/User/Editor";

// NotFound
import NotFound from "@/views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      auth: true,
    },
    component: Dashboard,
  },
  {
    path: "/promotional",
    name: "promotional",
    meta: {
      auth: true,
    },
    component: Promotional,
  },
  {
    path: "/promotional/:id",
    name: "promotional-editor",
    props: true,
    meta: {
      auth: true,
    },
    component: PromotionalEditor,
  },

  {
    path: "/car-repair",
    name: "car-repair",
    meta: {
      auth: true,
    },
    component: CarRepair,
  },
  {
    path: "/car-repair/:id",
    name: "car-repair-editor",
    props: true,
    meta: {
      auth: true,
    },
    component: CarRepairEditor,
  },
  {
    path: "/occurrence",
    name: "occurrence",
    meta: {
      auth: true,
    },
    component: Occurrence,
  },
  {
    path: "/occurrence/:id",
    name: "occurrence-editor",
    props: true,
    meta: {
      auth: true,
    },
    component: OccurrenceEditor,
  },
  {
    path: "/quotation",
    name: "quotation",
    meta: {
      auth: true,
    },
    component: Quotation,
  },
  {
    path: "/quotation/:id",
    name: "quotation-editor",
    props: true,
    meta: {
      auth: true,
    },
    component: QuotationEditor,
  },
  {
    path: "/inspection",
    name: "inspection",
    meta: {
      auth: true,
    },
    component: Inspection,
  },
  {
    path: "/inspection/:id",
    name: "inspection-editor",
    props: true,
    meta: {
      auth: true,
    },
    component: InspectionEditor,
  },
  {
    path: "/contract",
    name: "contract",
    meta: {
      auth: true,
    },
    component: Contract,
  },
  {
    path: "/contract/:id",
    name: "contract-editor",
    props: true,
    meta: {
      auth: true,
    },
    component: ContractEditor,
  },
  {
    path: "/company",
    name: "company",
    meta: {
      auth: true,
    },
    component: Company,
  },
  {
    path: "/company/:id",
    name: "company-editor",
    props: true,
    meta: {
      auth: true,
    },
    component: CompanyEditor,
  },
  {
    path: "/user",
    name: "user",
    meta: {
      auth: true,
    },
    component: User,
  },
  {
    path: "/user/:id",
    name: "user-editor",
    props: true,
    meta: {
      auth: true,
    },
    component: UserEditor,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: 'Login',
      auth: false,
    },
  },
  {
    path: "/*",
    name: 'not-found',
    component: NotFound,
    meta: {
      auth: false,
    },
  },
];

export default function(store) {
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

  const DEFAULT_TITLE = 'APP NOVA OPÇÃO';
  router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title ? `${to.meta.title - DEFAULT_TITLE}` : DEFAULT_TITLE;
    });
  });

  router.beforeEach((to, from, next) => {
    if(to.name == 'home' || to.path == '/') {
      return next({
        name: 'dashboard',
      })
    }

    const isAuthenticated = !!store.state.auth.token;
    if (to.meta && to.meta.auth) {
      if (isAuthenticated) {
        return next();
      }

      return next({
        path: "/login",
      });
    }
    // Keep inaccessible login screens when is logged in
    if (isAuthenticated) {
      return next({
        path: from.path,
      });
    }
    return next();
  });

  return router;
}
