import axios from "axios";
import _ from "lodash";
import { Notification } from 'element-ui'

const state = {};

const mutations = {};

const actions = {
  async filterUsers(context, params) {
    try {
      const { data } = await axios.get("/user", {
        params
      });

      return data
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Erro de carregamento',
        message: _.get(
          err,
          "response.message",
          "Não foi possível carregar os usuários"
        ),
        duration: 6000,
      });
    }
  },

  async list(context, params) {
    try {
      const { data } = await axios.get("/user/search", {
        params
      });

      return data
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Erro de carregamento',
        message: _.get(
          err,
          "response.message",
          "Não foi possível carregar os usuários"
        ),
        duration: 6000,
      });
    }
  },


  async update(context, { id, user }) {
    try {
      const { data } = await axios.put("/user/" + id, user);

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Erro ao atualizar',
        message: _.get(
          err,
          "response.message",
          "Não foi possível atualizar o usuário"
        ),
        duration: 6000,
      });
    }
  },

  async create(context, user) {
    try {
      const { data } = await axios.post("/user", user);

      return data;
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Erro ao criar',
        message: _.get(
          err,
          "response.data.error",
          "Não foi possível criar o usuário"
        ),
        duration: 6000,
      });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
