<template>
  <div id="bar-chart" ref="realtimeChart"></div>
</template>
<script>
import ApexCharts from 'apexcharts'

export default {
  data() {
    return {
      chart: null
    }
  },
  props: {
    dashboard: {
      type: Object
    }
  },
  watch: {
    dashboard: {
      handler() {
        this.renderGraphic()
      },
      deep: true
    }
  },
  methods: {
    renderGraphic() {
      const quotationCount = this.dashboard && this.dashboard.quotationCount || 0
      const inspectionCount = this.dashboard && this.dashboard.inspectionCount || 0
      const contractCount = this.dashboard && this.dashboard.contractCount || 0
      var options = {
        series: [{
          name: 'Total',
          data: [quotationCount, inspectionCount, contractCount]
        }],
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        
        xaxis: {
          categories: ["Cotações", "Vistorias", "Contratos"],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          }
        
        },
      };

      if(this.chart) {
        return this.chart.updateSeries(options.series, false, true);
      }
      this.chart = new ApexCharts(document.querySelector("#bar-chart"), options);
      this.chart.render();
    }
  }
};
</script>