<template>
  <div id="line-chart" ref="realtimeChart"></div>
</template>
<script>
import ApexCharts from 'apexcharts'
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  data() {
    return {
      chart: null
    }
  },
  props: {
    quotationStatus: {
      type: Object
    },
    periodUnit: {
      type: String,
    }
  },
  watch: {
    quotationStatus: {
      handler() {
        this.renderGraphic()
      },
      deep: true
    }
  },
  methods: {
    renderGraphic() {
      if(!this.quotationStatus) return

      const periodUnitFormat = {
        'month': 'MMMM/YYYY',
        'day': 'DD/MM/YYYY',
        'week': 'DD/MM/YYYY'
      }[this.periodUnit]

      let unitBreaks = []
      unitBreaks = this.quotationStatus.opened.map((s) => {
        return dayjs(s._id).add(1, 'day').format(periodUnitFormat)
      })

      const periodUnitLabel = {
        'month': 'Meses',
        'day': 'Dias',
        'week': 'Semanas'
      }[this.periodUnit]

      const opened = this.quotationStatus.opened.map((s) => {
        return s.total
      })
      const closed = this.quotationStatus.closed.map((s) => {
        return s.total
      })

      var options = {
        series: [
          {
            name: "Em aberto",
            data: opened
          },
          {
            name: "Fechadas",
            data: closed
          }
        ],
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Cotações em aberto X fechadas',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: unitBreaks,
          title: {
            text: periodUnitLabel
          }
        },
        yaxis: {
          title: {
            text: 'Quantidade'
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      };

      if(this.chart) {
        this.chart.updateOptions(options);
        this.chart.updateSeries(options.series, false, true);
        return
      }
      this.chart = new ApexCharts(document.querySelector("#line-chart"), options);
      this.chart.render();
    }
  }
};
</script>