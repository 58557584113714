import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#252525",
        secondary: "#C7A332"
      }
    }
  }
};

export default new Vuetify(opts);
