export default [
  { text: "Carro", value: "car", icon: "mdi-car" },
  { text: "Moto", value: "motorcycle", icon: "mdi-motorbike" },
  { text: "Pick-up / SUV", value: "suv", icon: "mdi-car-estate" },
  { text: "Utilitário", value: "utility", icon: "mdi-van-utility" },
  { text: "Caminhão", value: "truck", icon: "mdi-truck" },
  { text: "Somente Carreta", value: "trailer-only", icon: "mdi-truck-trailer" },
  {
    text: "Somente Terceiro",
    value: "third-person-only",
    icon: "mdi-account-arrow-right-outline"
  }
];
