<template>
  <v-layout class="login pa-0 d-flex flex-column page">
    <v-row
      justify="center"
      class="form-login primary-gradient-default ml-0 mb-0 rounded-tl-xl"
    >
      <v-container class="align-self-center">
        <v-row justify="center">
          <v-col cols="10" xs="8" sm="8" md="6" lg="4">
            <img
              class="white-logo"
              alt="Logotipo Branco"
              src="@/assets/logo-black.svg"
              width="250px"
            />
          </v-col>
          <v-col cols="10" xs="8" sm="8" md="6" lg="4">
            <v-card-text class="pa-0">
              <v-form>
                <div
                  class="cpf-input"
                  @click="
                    hasAccount && !$wait.waiting('loading')
                      ? setAuthKind(null)
                      : null
                  "
                >
                  <v-text-field
                    :disabled="$wait.waiting('loadingAuthKind') || hasAccount"
                    v-model="cpf"
                    autocomplete="off"
                    id="cpf"
                    label="CPF"
                    name="cpf"
                    class="input"
                    v-mask="['###.###.###-##']"
                    @keyup.enter="login()"
                  ></v-text-field>
                </div>
                <div class="password-input" :class="!hasAccount ? 'hide' : ''">
                  <v-text-field
                    :disabled="$wait.waiting('loading')"
                    v-model="password"
                    class="input"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    name="input-10-1"
                    label="SENHA"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="login()"
                  ></v-text-field>
                </div>
              </v-form>
              <router-link
                :class="{
                  hide: !hasAccount,
                  disabled: $wait.waiting('loading'),
                }"
                class="
                  forgot-password
                  primary--text
                  mb-4
                  text-decoration-none
                  float-left
                  text-link
                "
                :to="
                  !$wait.waiting('loading')
                    ? { name: 'forgot-password', params: { cpf: cpf } }
                    : {}
                "
              >
                Esqueci minha senha
              </router-link>
            </v-card-text>
            <v-btn
              :loading="
                $wait.waiting('loading') || $wait.waiting('loadingAuthKind')
              "
              @click="login()"
              block
              large
              class="btn mb-4"
              color="secondary-gradient primary--text"
            >
              ENTRAR
              <v-spacer></v-spacer>
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-dialog
          v-model="showDialogAuthKind"
          :show-cancel-button="false"
          :show-confirm-button="false"
          class="pa-4"
        >
          <h4>Escolha o seu nível de acesso</h4>
          <button
            @click="selectAuthKind(kind.value)"
            v-ripple
            class="auth-kind-card elevation-3 mt-4 px-3"
            v-for="(kind, index) in authKindsPossibles"
            :key="index"
          >
            <div class="auth-kind-avatar" :class="kind.color">
              <v-icon color="white">{{ kind.icon }}</v-icon>
            </div>

            <div class="ml-2">
              <span>{{ kind.name }}</span>
            </div>
          </button>
        </v-dialog>
      </v-container>
    </v-row>
  </v-layout>
</template>
<script>
import { validate } from "gerador-validador-cpf";
import { mapActions, mapMutations, mapState } from "vuex";
import { mapWaitingActions } from "vue-wait";
import { Notification } from "element-ui";

export default {
  data() {
    return {
      cpf: null,
      password: null,
      showPassword: false,
      showDialogAuthKind: false,
      authKindsPossibles: [],
      authKinds: [
        {
          name: "Associado",
          value: "associated",
          icon: "uil-user-square",
          color: "blue-medium",
        },
        {
          name: "Consultor",
          value: "user",
          icon: "uil-user-md",
          color: "red",
        },
      ],
    };
  },
  name: "Login",

  computed: {
    ...mapState("auth", ["authKind"]),

    hasAccount() {
      return !!this.authKind && !!this.cpf;
    },
  },

  methods: {
    ...mapMutations("auth", ["setAuthKind"]),
    ...mapActions("auth", ["lookupAuthKind", "auth"]),
    ...mapWaitingActions("auth", {
      lookupAuthKind: "loadingAuthKind",
      auth: "loading",
    }),

    selectAuthKind(kind) {
      this.setAuthKind(kind);
      this.showDialogAuthKind = false;
    },

    async login() {
      if (!this.cpf || this.cpf.length < 14) {
        return Notification({
          type: "error",
          message: "Digite seu CPF completo",
          duration: 5000,
        });
      }

      if (!validate(this.cpf)) {
        return Notification({
          type: "error",
          message: "Seu CPF é inválido, verifique se está correto",
          duration: 8000,
        });
      }

      if (!this.authKind) {
        const authKind = await this.lookupAuthKind({
          cpf: this.cpf,
        });

        if (!authKind || !authKind.length) {
          return Notification({
            type: "error",
            message:
              "Sua conta não foi localizada em nossa base de dados. Entre em contato conosco pelo site www.novaopcao.org.br",
            duration: 15000,
          });
        }
        if (authKind.length >= 2) {
          await Promise.all(
            authKind.map((kind) => {
              const authKindPossible = this.authKinds.find(
                (authKind) => authKind.value == kind
              );
              if (authKindPossible) {
                this.authKindsPossibles.push(authKindPossible);
              }
            })
          );
          return (this.showDialogAuthKind = true);
        }

        return this.setAuthKind(authKind[0]);
      }

      const token = await this.auth({
        cpf: this.cpf,
        kind: this.authKind,
        password: this.password,
      });
      if (token) {
        this.$router.push({
          name: "home",
        });
      }
    },
  },
};
</script>
<style scoped>
.form-login {
  z-index: 20;
  margin-top: -24px;
  width: 100%;
}
.login {
  height: 100vh;
  width: 100vw;
  justify-content: center;
  overflow: hidden;
}
.login-area {
  flex: 0 0 auto;
  width: 100vw;
  max-height: 250px;
  background: var(--dark-blue);
  z-index: 10;
}
.hide {
  display: none;
}
.auth-kind-card {
  display: flex;
  height: 60px;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  border: none;
}
.auth-kind-avatar {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.forgot-password.disabled {
  cursor: not-allowed;
  color: var(--grey) !important;
}
.app-version {
  font-size: 12px;
  text-align: right;
  width: 100%;
}
</style>
