<template>
  <v-row justify="center" class="scroll-y" v-if="data">
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="6"
    >
      <v-card ref="form">
        <h2 class="primary--text pl-3 pt-3">
          {{ isEdit ? "Atualizar dados do usuário" : "Cadastrar usuário" }}
        </h2>
        <v-card-text>
          <div v-if="data.createdAt">
            <strong>Data de criação:</strong>
            {{ data.createdAt | FormatDate('datetime') }}
          </div>

          <v-switch
            v-if="isEdit"
            v-model="data.active"
            label="Usuário ativo"
          ></v-switch>

          <v-text-field
            v-model="data.name"
            label="Nome do usuário"
            placeholder="Ex: John Doe"
            required
          ></v-text-field>
          <v-text-field
            v-model="data.cpf"
            label="CPF"
            placeholder="Ex: 111.111.111-11"
            v-mask="['###.###.###-##']"
            autocomplete="off"
            required
          ></v-text-field>

          <v-select
            v-model="data.companiesIds"
            :items="mapCompanies"
            attach
            chips
            label="Empresas vinculadas"
            :loading="$wait.waiting('loadingMe')"
            multiple
          ></v-select>

          <v-text-field
            v-model="data.email"
            label="Email"
            :rules="[rules.required, rules.email]"
            placeholder="Ex: john@novaopcao.org.br"
            required
          ></v-text-field>
          <v-text-field
            v-model="data.phone"
            label="Telefone"
            placeholder="Ex: (11) 99999-9999"
            v-mask="['(##) #####-####']"
          ></v-text-field>
          <v-select
            v-model="data.roles"
            :items="roles"
            attach
            chips
            label="Permissão"
            multiple
            class="mb-6"
          ></v-select>

          <v-text-field
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.min]"
            :type="show1 ? 'text' : 'password'"
            label="Senha"
            v-model="data.password"
            class="mt-12"
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            :rules="[rules.min]"
            label="Confirmar senha"
            v-model="data.password2"
            :hint="data.password2 != data.password ? 'As senhas não são idênticas' : ''"
            :error="data.password2 != data.password"
            @click:append="show2 = !show2"
          ></v-text-field>

          * Se as senhas forem deixadas em branco, a senha do usuário não será alterada
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn @click="$router.back()" text>
            <v-icon>mdi-chevron-left</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            :disabled="!allRequiredFieldsFilled || !dataIsModified"
            large
            class="btn mt-4 secondary primary--text"
            @click="submit()"
            :loading="$wait.waiting('creating') || $wait.waiting('updating')"
          >
            {{ isEdit ? "Salvar" : "Cadastrar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapWaitingActions } from "vue-wait";
import { validate } from "gerador-validador-cpf";
import checksum from '@/helpers/checksum'
import _ from "lodash";

export default {
  name: "UserEditor",

  data() {
    return {
      isEdit: false,
      roles: [
        {
          text: 'Administrador',
          value: 'admin',
        },
        {
          text: 'Consultor Líder',
          value: 'owner'
        },
        {
          text: 'Consultor',
          value: 'employee'
        },
        {
          text: 'Backoffice',
          value: 'backoffice'
        }
      ],
      rules: {
        required: value => !!value || 'Campo obrigatório.',
        min: value => value && value.length >= 6 || 'Minímo de 6 caracteres',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inválido.'
        },
      },
      initialState: null,
      show1: false,
      show2: false,
      data: {
        createdAt: null,
        name: null,
        cpf: null,
        email: null,
        phone: null,
        roles: null,
        active: null,
        password: null,
      },
    };
  },

  async created() {
    const id = _.get(this.$route, "params.id", null);
    const data = _.get(this.$route, "params.data", null);

    if (id && id != "new" && data) {
      this.data = Object.assign({}, data);
      this.isEdit = true;
    } else if (id && id != 'new' && !data) {
      this.data = null
      return this.$router.replace({ name: "user" });
    }

    await this.getMe()
    setTimeout(() => {
      this.initialState = Object.assign({}, this.data)
    }, 100)
  },

  watch: {
    dataIsModified(val) {
      if(!val) {
        return window.onbeforeunload = null
      }
      window.onbeforeunload = function(){
        return 'exit';
      };
    }
  },

  async beforeRouteLeave(to, from, next){
    if(!this.dataIsModified || this.confirmExit) {
      return next()
    }

    if (window.confirm("Você tem alterações que não foram salvas. Deseja realmente sair?")) {
      return next()
    }
    try {
      await this.$router.replace(from)
    } catch (err) {
      return err;
    }
  },

  computed: {
    ...mapState('auth', ['companies']),
    dataIsModified() {
      return checksum(this.initialState) != checksum(this.data)
    },

    allRequiredFieldsFilled() {
      const fields = [
        'name', 
        'cpf', 
        'email',
        'roles'
      ]
      if(!this.isEdit) {
        fields.push('password', 'password2')
        return this.data.password2 === this.data.password && fields.every((field) => this.data[field])
      }
      return fields.every((field) => this.data[field])
    },

    mapCompanies() {
      return this.companies.map((company) => ({
        text: company.name,
        value: company.id,
      }));
    },
  },

  methods: {
    ...mapActions("auth", ["getMe"]),
    ...mapActions("user", [
      "create",
      "update",
    ]),
    ...mapWaitingActions("user", {
      create: "creating",
      update: "updating",
    }),
    ...mapWaitingActions("auth", {
      getMe: "loadingMe",
    }),

    submit() {
      if (!validate(this.data.cpf)) {
        return this.$notify({
          type: 'error',
          title: 'CPF inválido',
          message: "Esse CPF é inválido, verifique se está correto",
          duration: 8000
        });
      }

      if (this.isEdit) {
        return this.updateRequest();
      }

      return this.createRequest();
    },

    async createRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.create(data);
      if (!result) return;

      this.$notify({ type: "success", title: 'Sucesso', message: "Usuário cadastrado com sucesso" });
      this.reset(result)
      this.$router.replace({ name: 'user' })
    },

    async updateRequest() {
      const data = Object.assign({}, this.data);
      const result = await this.update({
        id: this.data.id,
        user: data,
      });
      if (!result) return;

      this.reset(result)
      this.$notify({ type: "success", title: 'Sucesso', message: "Dados atualizados com sucesso" });
    },

    reset(result) {
      this.initialState = Object.assign({}, result)
      this.data = Object.assign({}, result)
    }
  },
};
</script>

<style scoped>
.scroll-y {
  overflow-y: auto;
  height: calc(100vh - 56px);
}
</style>
