import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

// modules
import app from "@/store/app";
import auth from "@/store/auth";
import promotional from "@/store/promotional";
import carRepair from "@/store/car-repair";
import occurrence from "@/store/occurrence";
import quotation from "@/store/quotation";
import inspection from "@/store/inspection";
import contract from "@/store/contract";
import company from "@/store/company";
import user from "@/store/user";
import dashboard from "@/store/dashboard";

Vue.use(Vuex);

export default function createStore() {
  const options = {
    strict: true,
    modules: {
      app,
      auth,
      promotional,
      carRepair,
      occurrence,
      quotation,
      inspection,
      contract,
      company,
      user,
      dashboard,
    },
    plugins: [],
  };

  if (isLocalStorageEnabled()) {
    options.plugins.push(
      persistedState({
        paths: [
          "auth",
          "app.features",
        ],
        key: "nova-opcao",
      })
    );
  }

  return new Vuex.Store(options);
}

function isLocalStorageEnabled() {
  try {
    return window.localStorage instanceof Storage;
  } catch (error) {
    return false;
  }
}
